<template>
  <div>
    <div ref="document">
      <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page" :aria-label="`${constData.chouhyouId}-${chouhyou.page}`">
        <div v-if="chouhyou.tempKbn == constData.tempKbnNormal">
          <TemplateNormal :id="constData.chouhyouId + chouhyou.page" />
        </div>
        <div v-if="chouhyou.tempKbn == constData.tempKbnNotFirst">
          <TemplateNotFirst :id="constData.chouhyouId + chouhyou.page" />
        </div>
      </div>
    </div>
    <v-btn
      v-if="showButton"
      class="mb-2 mx-2"
      dark
      color="secondary"
      rounded
      fixed
      center
      bottom
      @click="sendMail"
    >
      <v-icon left>mdi-email</v-icon>承認メール送信
    </v-btn>
  </div>
</template>
<script>
import TemplateNormal from '@/assets/svg/estimate/normal.svg';
import TemplateNotFirst from '@/assets/svg/estimate/notFirst.svg';
import { setPaperA4, splitMultiRowString } from '@/assets/js/common';
import { listV_MITSUMORI_DATA_WITH_MEISAI, getM_SHOKISETTEI, getM_TANTOSHA } from '@/graphql/queries';
import { sendRawEmail } from "@/graphql/mutations";
import { API, graphqlOperation, Storage } from 'aws-amplify';
import html2pdf from 'html2pdf.js'
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { ALERT_MESSAGE_COMMON_ERROR, CONFIRM_MESSAGE_SEND_EMAIL, ALERT_NO_TANTOSHA_DATA, ALERT_TOO_LONG_SUMMARY, ALERT_TOO_LONG_COMMENT } from "@/assets/js/dialog_messages";
export default {
  name: 'Estimate',
  /* コンポーネント */
  components: {
    TemplateNormal,
    TemplateNotFirst
  },
  /* パラメータ */
  props:['mitsumoriDataId'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 13,
        cntNotFirst: 17,
        tempKbnNormal: 1,
        tempKbnNotFirst: 2,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      title: '見積書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '210mm',
        height: '294mm',
      },
      listChouhyou: [],
      replacementsCommon: [],
      user: null,

      // 見積書番号
      mitsumorishoNumber: 0,
      // PDFファイルS3情報
      fileInfo: {
        filePath: '',
        fileName: '',
      },
      // 「メール送信」ボタン表示フラグ
      showButton: false,
      // 見積データの部門コード
      estimateBumonCd: 0,
      // 見積データの担当者コード
      estimateTantoshaCd: 0,
      //総ページ数
      pageCount: 0,

      // 印鑑楕円位置保持用
      colBumonchoStampCX: 1359,
      colBumonchoStampCY: 661,
      colTantoshaStampCX: 1525,
      colTantoshaStampCY: 661,

      // 備考欄の出力文字数（偶数のみ許可）
      summaryMaxLength: 30,
      // 備考が31文字以上登録されていた場合のアラートを表示済みの場合True
      tooLongSummaryNotified: false,
      // コメント欄の出力文字数（偶数のみ許可）
      commentMaxLength: 100,
      // コメントが101文字以上登録されていた場合のアラートを表示済みの場合True
      tooLongCommentNotified:false,
    }
  },
  created() {
    // ヘッダ・フッタを非表示
    this.$store.commit("setShowNav", false);
  },
  /* マウント */
  mounted() {
   // 印刷レイアウト設定
    setPaperA4();

    this.fetchData();
  },
  /* 関数群 */
  methods:{
    async fetchData(){
      // 指定された見積データを設定
      await this.setEstimate();
    },
    /* 見積データ設定 */
    async setEstimate(){
      // 部門コード
      this.user = this.$store.getters.user;
      const bumonCd = this.user.BUMON_CD;

      // WHERE句生成
      let whereOptions = '';
      // 部門コード
      whereOptions += `AND BUMON_CD = ${bumonCd} `;
      // 見積番号
      whereOptions += `AND MITSUMORI_DATA_ID = ${this.mitsumoriDataId} `;
      // console.log(whereOptions);

      try {
        let result = await API.graphql(graphqlOperation(listV_MITSUMORI_DATA_WITH_MEISAI,{where_options: whereOptions}));
        let estimates = result.data.listV_MITSUMORI_DATA_WITH_MEISAI;
        // console.log(estimates);

        // 見積データの部門コード・担当者コード取得
        this.estimateBumonCd = estimates[0].BUMON_CD;
        this.estimateTantoshaCd = estimates[0].TANTOSHA_CD;

        if(estimates && estimates.length > 0){
          // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
          await this.initListChouhyou(estimates.length);
          // 共通の置換文字列設定
          await this.createReplacementsCommon(estimates);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(estimates);
          // 作成した置換文字データをSVGファイルに設定
          // await this.setChouhyou();
          const chouhyouSet = await this.setChouhyou();

          let result = false;
          if(chouhyouSet) {
            // PDFファイルデータ作成・ダウンロード
            result = this.exportToPDF();
          }

          if(result) {
            // メール送信ボタン表示
            this.showButton = true;
          }
        }
        // ローディングOFF
        this.$store.commit("setLoading", false);
      } catch (error) {
        console.log(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"]}, this.$route.name, 'setEstimate', TYPE["0003"], error);
        // アラート出力
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングOFF
        this.$store.commit("setLoading", false);
      }
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      console.log('initListChouhyou');
      if (productCnt <= this.constData.cntNormal) {
        // 製品が通常テンプレートの件数で収まる場合（通常帳票1ページのみ）
        this.listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnNormal, replacements: []});
        // 総ページ数を保持
        this.pageCount = 1;
      } else {
        let productCntRemain = productCnt;
        let page = 1;
        // 開始帳票
        this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnNormal, replacements: []});
        productCntRemain -= this.constData.cntNormal;
        page++;
        // 中間帳票
        while (productCntRemain > this.constData.cntNotFirst) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
          this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnNotFirst, replacements: []});
          productCntRemain -= this.constData.cntNotFirst;
          page++;
        }
        // 終了帳票
        this.listChouhyou.push({page: page, tempKbn: this.constData.tempKbnNotFirst, replacements: []});
        // 総ページ数を保持
        this.pageCount = page;
      }
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(result){
      // console.log('createReplacementsCommon');
      // 作成日付
      this.replacementsCommon.push({key: '%作成日付%', value: result[0].SAKUSEI_DATE ?? '', textAnchor: 'middle', textLength: 251});
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result){
      // console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      for (let i = 0; i < this.listChouhyou.length; i++) {
        // SVGファイルの置換用文字列
        let replacements = [];
        // 見積番号
        replacements.push({key: '%見積番号%', value: result[0].MITSUMORI_NO + ' - ' + ('00' + (this.listChouhyou[i].page)).slice(-2), textAnchor: 'middle', textLength: 251});
        // 通常、または、開始帳票
        if (this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal) {
          // 件名
          replacements.push({key: '%件名%', value: result[0].KENMEI_NAME ?? '', textAnchor: 'start', textLength: 764});
          // CSS得意先名
          replacements.push({key: '%CSS得意先名%', value: result[0].CSS_TOKUISAKI_NAME ?? '', textAnchor: 'start', textLength: 665});
          // 有効期限
          replacements.push({key: '%期%', value: result[0].YUKOKIGEN_SU ?? '', textAnchor: 'end', textLength: 89});
          // 納期
          replacements.push({key: '%納%', value: result[0].NOKI_SU ?? '', textAnchor: 'end', textLength: 95});
          // 営業所情報
          await this.setOfficesData(replacements);
          // 印鑑日付
          const now = new Date();
          const today = `'${now.getFullYear().toString().substr(2, 2)}/${now.getMonth()+1}/${now.getDate()}`;
          for (let dCount = 0; dCount < 2; dCount++) {
            replacements.push({key: `%印日${dCount}%`, value: today, textAnchor: 'middle', textLength: 85});
          }
          // 部門用印
          // 承認者が登録されているかチェック
          const managerRegisterd = this.user.SHONINSHA_SIGN_NAME ? true : false;
          if (managerRegisterd) {
            replacements.push({key: '%長名%', value: this.user.SHONINSHA_SIGN_NAME, textAnchor: 'middle', textLength: 85});
          } else {
            replacements.push({key: '%長名%', value: '', textAnchor: 'middle', textLength: 85});
            // 登録されていない場合、承認者の印影を非表示にする
            const elements = document.getElementsByClassName('bumoncho-stamp');
            for(var elementsCount = 0; elementsCount < elements.length; elementsCount++){
              elements[elementsCount].style.visibility = 'hidden';
            }
          }
          replacements.push({key: '%長名%', value: this.user.SHONINSHA_SIGN_NAME ?? '', textAnchor: 'middle', textLength: 85});
          // 担当者印
          console.log('担名', this.user.TANTOSHA_NAME);
          replacements.push({key: '%担名%', value: this.user.TANTOSHA_NAME ?? '', textAnchor: 'middle', textLength: 112});
          // 合計金額（見積種類が「2:単価見積り」の場合、固定文言）
          let estimateAmount = result.reduce(function(sum, item){
            return sum + item.KINGAKU_GK;
          }, 0);
          replacements.push({key: '%合計金額%', value: estimateAmount.toLocaleString(), textAnchor: 'end', textLength: 313});
          // 定価合計
          let listPriceAmount = result.reduce(function(sum, item){
            return sum + (item.TEKA_GK * item.SURYO_SU);
          }, 0);
          replacements.push({key: '%定価合計%', value: listPriceAmount ? listPriceAmount.toLocaleString() : '', textAnchor: 'end', textLength: 384});
        }
        // 帳票毎に設定可能な製品の件数
        let productCntByChouhyou = 0;
        if (this.listChouhyou[i].tempKbn == this.constData.tempKbnNormal) {
          productCntByChouhyou = this.constData.cntNormal;
        } else {
          productCntByChouhyou = this.constData.cntNotFirst;
        }
        // 製品
        for (let j = 0; j < productCntByChouhyou; j++){
          if(productsIndex < result.length){
            // 明細番号
            replacements.push({key: `%番${j}%`, value: result[productsIndex].MEISAI_NO, textAnchor: 'end', textLength: 89});
            // 商品名
            replacements.push({key: `%商品名${j}%`, value: result[productsIndex].SHOHIN_NAME, textAnchor: 'start', textLength: 341});
            // 型名
            replacements.push({key: `%型名${j}%`, value: result[productsIndex].KATABAN_NO, textAnchor: 'start', textLength: 253});
            // 数量
            replacements.push({key: `%数${j}%`, value: result[productsIndex].SURYO_SU.toLocaleString(), textAnchor: 'end', textLength: 96});
            // 単価
            replacements.push({key: `%単価${j}%`, value: result[productsIndex].TANKA_GK.toLocaleString(), textAnchor: 'end', textLength: 120});
            // 金額
            replacements.push({key: `%金額${j}%`, value: result[productsIndex].KINGAKU_GK.toLocaleString(), textAnchor: 'end', textLength: 125});
            // 定価
            if (result[productsIndex].TEKA_GK == 0) {
              // 0の場合は「オープン」表記
              replacements.push({key: `%定価${j}%`, value: "オープン", textAnchor: 'middle', textLength: 128});  
            } else {
              replacements.push({key: `%定価${j}%`, value: result[productsIndex].TEKA_GK?.toLocaleString() ?? '', textAnchor: 'end', textLength: 125});
            }

            // 備考
            if(!this.tooLongSummaryNotified && result[productsIndex].BIKO?.length > this.summaryMaxLength) {
              // 備考欄が最大文字長を超えていたら1度だけアラートを表示
              alert(ALERT_TOO_LONG_SUMMARY);
              this.tooLongSummaryNotified = true;
            }
            this.setSummary(replacements, j, result[productsIndex]?.BIKO ?? '');

            // コメント
            if(!this.tooLongCommentNotified && result[productsIndex].COMMENT?.length > this.commentMaxLength) {
              // コメント欄が最大文字長を超えていたら1度だけアラートを表示
              alert(ALERT_TOO_LONG_COMMENT);
              this.tooLongCommentNotified = true;
            }
            let aryComment = splitMultiRowString(result[productsIndex]?.COMMENT ?? '', 100);
            replacements.push({key: `%コメント${j}%`, value: aryComment[0], textAnchor: 'start', textLength: 1402});
            productsIndex++;
          } else {
            // 明細番号
            replacements.push({key: `%番${j}%`, value: '', textAnchor: 'end', textLength: 89});
            // 商品名・型名・コメント
            for (let cnt = 0; cnt < 2; cnt++) {
              replacements.push({key: `%商品名${j}%`, value: '', textAnchor: 'start', textLength: 341});
              replacements.push({key: `%型名${j}%`, value: '', textAnchor: 'start', textLength: 253});
              replacements.push({key: `%備考${j}%`, value: '', textAnchor: 'start', textLength: 234});
              replacements.push({key: `%コメント${j}%`, value: '', textAnchor: 'start', textLength: 1412});
            }
            // 単価
            replacements.push({key: `%単価${j}%`, value: '', textAnchor: 'end', textLength: 240});
            // 定価
            replacements.push({key: `%定価${j}%`, value: '', textAnchor: 'end', textLength: 128});
            // 金額
            replacements.push({key: `%金額${j}%`, value: '', textAnchor: 'end', textLength: 240});

            if (whiteFlg == true) {
              // 上記以外で以下余白を設定済み
              // 数量
              replacements.push({key: `%数${j}%`, value: '', textAnchor: 'end', textLength: 101});
            } else {
              console.log(`i = ${i}, this.listChouhyou.length = ${this.listChouhyou.length}, j = ${j}, productCntByChouhyou = ${productCntByChouhyou}`);
              if (i + 1 == this.listChouhyou.length && j + 1 == productCntByChouhyou) {
                // 最終帳票、かつ、（最終行の2行前で消費税あり、または、最終帳票の1行前）の場合、余白がないため、「以上」とする。
                // 数量
                replacements.push({key: `%数${j}%`, value: '以上', textAnchor: 'middle', textLength: 101});
              } else {
                // 数量
                replacements.push({key: `%数${j}%`, value: '以下余白', textAnchor: 'middle', textLength: 101});
              }

              whiteFlg = true;
            }
            // 備考
            this.setSummary(replacements, j, '');
          }
        }
        // ページ番号
        replacements.push({key: '%ページ番号%', value: `${this.listChouhyou[i].page} / ${this.pageCount} ページ`, textAnchor: 'start', textLength: 196});

        this.listChouhyou[i].replacements = replacements;
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      // 部門コード
      const user = this.$store.getters.user;
      const bumonCd = user.BUMON_CD;

      // 営業所データ取得
      let result = await API.graphql(graphqlOperation(getM_SHOKISETTEI, {BUMON_CD: bumonCd}));
      // console.log({result});
      const bumon = result.data.getM_SHOKISETTEI;
      // console.log({bumonData});
      // // TODO データが存在しなかった場合の処理
      // const bumon = bumonData[0];
      // console.log({bumon});

      // 部門名称
      replacements.push({key: '%部門%', value: bumon.BUMON_NAME ?? '', textAnchor: 'start', textLength: 455});
      // 郵便
      replacements.push({key: '%郵便番号%', value: bumon.YUBIN_NO ?? '', textAnchor: 'start', textLength: 229});
      // 住所
      replacements.push({key: '%住所%', value: bumon.JUSHO_NAME ?? '', textAnchor: 'start', textLength: 560});
      // TEL
      replacements.push({key: '%TEL%', value: bumon.DENWA_NO ?? '', textAnchor: 'start', textLength: 225});
      // FAX
      replacements.push({key: '%FAX%', value: bumon.FAX_NO ?? '', textAnchor: 'start', textLength: 225});
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements, rowNum, summary) {
      let arySummary = splitMultiRowString(summary, 15);
      for (let i = 0; i < 2; i++) {
        if (i < arySummary.length) {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i}%`, value: arySummary[i], textAnchor: 'start', textLength: 234});
        } else {
          // 備考
          replacements.push({key: `%備考${rowNum}-${i}%`, value: '', textAnchor: 'start', textLength: 234});
        }
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      // console.log('setChouhyou');
      for (let i = 0; i < this.listChouhyou.length; i++){
        // console.log(`this.constData.chouhyouId + this.listChouhyou[i].page = ${this.constData.chouhyouId + this.listChouhyou[i].page}`);
        let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
        this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
        await this.setSize(svgDoc);
      }
      return true;
    },
    /* 帳票をPDFダウンロード */
    exportToPDF() {
      try {
        const pages = Array.from(document.querySelectorAll(`div[aria-label^="${this.constData.chouhyouId}-"]`));

        let worker = html2pdf()
          .set({
            filename: '見積書.pdf',
            image: {type: 'jpeg', quality: 0.55},
            html2canvas: { scale: 2 },
          })
          .from(pages[0])

        if (pages.length > 1) {
          // jsPDFインスタンスを生成
          worker = worker.toPdf()

          // 1ページずつPDFをレンダリング
          for (const page of pages.slice(1)) {
            worker = worker
              .get('pdf')
              .then(pdf => {
                pdf.addPage()
              })
              .from(page)
              .toContainer()
              .toCanvas()
              .toPdf()
          }
        }

        worker = worker.save()
        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3014"]}, this.$route.name, 'exportToPDF', TYPE["0003"], error);
        return false;
      }
    },
    /* 帳票htmlからPDFファイルのデータを作成する */
    async createArrayBuffer() {
      try {
        // console.log('export to PDF');
        // console.log(this.$refs.document);
        const options = {
          image: {type: 'jpeg', quality: 0.95},
          html2canvas: { scale: 2 },
        }
        const arrayBuffer = await html2pdf().set(options).from(this.$refs.document).outputPdf('arraybuffer');
        // console.log({arrayBuffer});

        return arrayBuffer;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"]}, this.$route.name, 'createArrayBuffer', TYPE["0003"], error);
        throw error;
      }
    },
    /* PDFファイルをS3にアップロードする */
    async uploadPdf(arrayBuffer) {
      try {
        // 部門コード
        const user = this.$store.getters.user;
        // 現在時刻
        // Dateオブジェクトを作成
        const now = new Date() ;
        // UNIXタイムスタンプを取得する (ミリ秒単位)
        var unixTime = now.getTime() ;
        const filePath = `estimatePdf/${user.BUMON_CD + '-' +user.TANTOSHA_CD}/${unixTime}`;
        const fileName = '見積書.pdf';
        await Storage.put(`${filePath}${fileName}`, arrayBuffer);

        this.fileInfo = {
          filePath: filePath,
          fileName: fileName,
        }
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"]}, this.$route.name, 'uploadPdf', TYPE["0003"], error);
        throw error;
      }
    },
    /* S3からPDFファイルをダウンロードする */
    async downloadFile() {
      try {
        // S3からファイルを取得
        const storeGetResult = await Storage.get(`${this.fileInfo.filePath}${this.fileInfo.fileName}`, {download: true});
        
        // S3から取得したファイルをダウンロード
        const url = URL.createObjectURL(storeGetResult.Body);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.fileInfo.fileName || 'download';
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
          }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();

        return true;
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"]}, this.$route.name, 'downloadFile', TYPE["0003"], error);
        throw error;
      }
    },
    /**
     * メール送信ボタン押下処理
     */
    async sendMail() {
      // メソッド名を定義
      const method_name="sendMail"
      // オペレーションタイプを定義
      const operation_type="0011"
      // 見積データの担当者情報取得
      const tantoshaInfo = await this.getEstimateTantosha();
      if(!tantoshaInfo) return;
      // console.log({tantoshaInfo});
      if (!confirm(CONFIRM_MESSAGE_SEND_EMAIL)) {
        return;
      }
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // PDFファイルデータ作成
      const buffer = await this.createArrayBuffer();
      // S3にアップロード
      await this.uploadPdf(buffer);

      // 承認者存在チェック
      const shoninshaAddress = 
        tantoshaInfo.SHONINSHA_SIGN_NAME && tantoshaInfo.SHONINSHA_ADDRESS ?
        tantoshaInfo.SHONINSHA_ADDRESS :
        '';
      // 操作者情報
      const user = this.$store.getters.user;

      let emailText = "";
      emailText += `${tantoshaInfo.TANTOSHA_NAME}さん`;
      if (shoninshaAddress) {
        emailText += "\n";
        emailText += `（CC：${tantoshaInfo.SHONINSHA_SIGN_NAME}さん）`;
      }
      emailText += "\n\n";
      emailText += "お疲れ様です\n";
      emailText += "\n";
      emailText += `${tantoshaInfo.TANTOSHA_NAME}さんが担当者に指定された見積書が発行されました。\n`;
      emailText += "内容の確認をお願いいたします。\n";
      emailText += "\n";
      emailText += "※本メールはシステムから自動送信されています";
      let condition = {
        FROM: user.TANTOSHA_ADDRESS, // 画面操作者
        TO: tantoshaInfo.TANTOSHA_ADDRESS, // 見積担当者
        CC: shoninshaAddress, // 担当者の承認者
        SUBJECT: '見積書発行のお知らせ',
        TEXT: emailText,
        PATH: this.fileInfo.filePath,
      };
      try {
        let result = await API.graphql(
          graphqlOperation(sendRawEmail, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          // エラーが発生した場合の処理
          if (result.data.sendRawEmail.statusCode > 200) {
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, operation_type,);
            return;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return;
        }
        // console.log(result);
      } catch (error) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // console.log(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return;
      }
      // PDFファイルをS3から削除
      await Storage.remove(`${this.fileInfo.filePath}${this.fileInfo.fileName}`);

      // ローディングを解除
      this.$store.commit("setLoading", false);
      alert("メール送信が完了しました。");
      // 発注検索画面に戻る
      this.$emit("result", { sentMailFlg: true });
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /* 見積書の担当者・承認者データの取得 */
    async getEstimateTantosha() {
      // メソッド名を定義
      const method_name="sendMail"
      // オペレーションタイプを定義
      const operation_type="0011"

      // 検索条件
      let condition = {};
      try {
        // 検索条件
        condition = {
          BUMON_CD: this.estimateBumonCd,
          TANTOSHA_CD: this.estimateTantoshaCd,
        };
        // ログ出力(SQL-start)
        addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
        // データ取得
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_TANTOSHA, condition)
        );
        // 結果判定
        if (result.data.getM_TANTOSHA) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let tantoshaInfo = result.data.getM_TANTOSHA;
          return tantoshaInfo;
        } else {
          // データを取得できなかった場合
          alert(ALERT_NO_TANTOSHA_DATA);
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listM_TANTOSHA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // console.log(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "sendRawEmail", where_option:condition}, this.$route.name, method_name, operation_type, error);
        return null;
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      // console.log('setReplacements');
      for (const textElement of node.getElementsByTagName('text')) {
        for (const child of textElement.children) {
          if(child.tagName == 'tspan'){
            for (const commonReplacement of this.replacementsCommon) {
              if(child.innerHTML.indexOf(commonReplacement.key) != -1){
                this.setTspan(textElement, child, commonReplacement);
                break;
              }
            }
            for(let i = 0; i < replacements.length; i++){
              if(child.innerHTML.indexOf(replacements[i].key) != -1){
                if(replacements[i].key == '%担名%'){
                  this.setStamp(textElement, child, replacements[i], this.colTantoshaStampCX, this.colTantoshaStampCY);
                } else if(replacements[i].key == '%長名%') {
                  this.setStamp(textElement, child, replacements[i], this.colBumonchoStampCX, this.colBumonchoStampCY);
                } else{
                  this.setTspan(textElement, child, replacements[i]);
                }
                replacements.splice(i, 1);
                break;
              }
            }
          }
        }
      }
      for (const gElement of node.getElementsByTagName('g')) {
        this.setReplacements(gElement, replacements);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    /* ※注：伸ばし棒は縦書きされない（指摘があれば修正） */
    async setStamp(tagText, tagTspan, config, cx, cy){
      if (config.value.length == 0) {
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
      } else if (config.value.length == 1) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '65');
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        // 中央寄せ
        tagTspan.setAttribute('x', cx);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(cy) + 20);
      } else if (config.value.length == 2) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '48');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', cx);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(cy) - 5);
        // 2文字目
        tagText.append(await this.createStampTspan(cx, Number(cy) + 35, config.value.substr(1, 1)));
      } else if (config.value.length == 3) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '36');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', cx);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(cy) - 20);
        // 2文字目
        tagText.append(await this.createStampTspan(cx, Number(cy) + 10, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(cx, Number(cy) + 40, config.value.substr(2, 1)));
      } else if (config.value.length == 4) {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '24');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(cx) + 18);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(cy) - 8);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(cx) + 18, Number(cy) + 28, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(cx) - 18, Number(cy) - 8, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(cx) - 18, Number(cy) + 28, config.value.substr(3, 1)));
      } else {
        // 文字サイズ変更
        tagText.setAttribute('font-size', '16');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(cx) + 15);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(cy) - 9);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(cx) + 15, Number(cy) + 19, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(cx) - 15, Number(cy) - 18, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(cx) - 15, Number(cy) + 10, config.value.substr(3, 1)));
        // 5文字目
        tagText.append(await this.createStampTspan(Number(cx) - 15, Number(cy) + 38, config.value.substr(4, 1)));
      }
    },
    /* 印鑑用のtspan作成 */
    async createStampTspan(x, y, chr) {
      let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.innerHTML = chr;
      tspan.setAttribute('x', x);
      tspan.setAttribute('y', y);
      tspan.setAttribute('text-anchor', 'middle');
      return tspan;
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style>
  body
  {
    margin: 0;
    padding: 0;
    text-align: center;
  }
</style>